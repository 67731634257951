import { Box, Text } from "@chakra-ui/react"
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useData } from "../../data";
import RequestLists from "../notification/RequestLists"


const FriendRequests = () => {
    const [connections, setConnections] = useState([]);
    const loggedInUser = useSelector((state) => state?.user?.userInfo);
    const {
        data: connectionsData,
        isLoading: connectionsLoading,
        refetch: refetchConnections,
    } = useData(`users/${loggedInUser?.id}/connections?page_no=1&page_size=10`, 10000);
  const sortFn = (a, b) => moment(b?.lastMessage?.createdAt || b?.connection?.created_at || b?.createdAt).diff(moment(a?.lastMessage?.createdAt || a?.connection?.created_at || a?.createdAt), 'second')


    useEffect(() => {
        if (connectionsData?.data) {
          setConnections(
            connectionsData?.data?.connections?.sort(sortFn)
          );
        }
      }, [connectionsData?.data?.connections]);

    return (
        <Box className="flex flex-col p-3 w-full bg-white rounded-xl">
                <Text className="text-lg font-bold">
                    Request List
                </Text>
                <Box className="py-3">
                    { 
                        connections?.filter((connection) => !connection.date_accepted).length > 0 ? (
                            <RequestLists onReactToRequest={refetchConnections} />
                        ) : null
                    }
                </Box>
        </Box>
    )
}

export default FriendRequests