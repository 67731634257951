import React, { useState } from "react";
import {
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react";
import BrandButton from "../brandButton/BrandButton";
import { AiFillCustomerService, AiFillWarning } from "react-icons/ai";
import { mutateFunction, useMutation } from "../../libs/apis";
import { CustomToast } from "../toast/CustomToast";
import CreateComment from "../feed/CreateComment";

export default function CreateCommentModal({ 
  isOpen, 
  onClose, 
  post,
  refetchPost = () => {},
  onSuccess = () => {}
}) {

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            <Flex flexDirection="column">
              <CreateComment 
                onClose={onClose} 
                post={post}
                refetchPost={refetchPost}
                onSuccess={onSuccess}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
