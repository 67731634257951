import React, { useRef, useState, useEffect } from 'react';
import { Textarea } from '@chakra-ui/react';

function AutoResizeTextarea({ placeholder, minRows = 1, ...props }) {
  const textareaRef = useRef(null);
  const [rows, setRows] = useState(minRows);

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    
    // Reset height to detect actual scrollHeight
    textarea.style.height = 'auto';
    // Calculate new height based on content
    const newHeight = textarea.scrollHeight;
    textarea.style.height = `${newHeight}px`;
  };

  useEffect(() => {
    // Run once on mount to set initial height
    handleInput();
  }, []);

  return (
    <Textarea
      ref={textareaRef}
      rows={rows}
      placeholder={placeholder}
      onInput={handleInput}
      resize="none"        
      {...props}
    />
  );
}

export default AutoResizeTextarea;
