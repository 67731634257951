import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewMessages } from "../redux-toolkit/reducers/messagesSlice";
import socketIOClient from 'socket.io-client';
import { BASE_URL } from "../helpers/constant";
import UserService from "../utils/UserService";

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
    const userInformation = useSelector(state => state?.user?.userInfo);
    const [activePage, setActivePage] = useState('/feed')
    const [socket, setSocket] = useState(false);
    const dispatch = useDispatch()

    const connectSocket = () => {
       try {
        if(!socket){
            const Socket = socketIOClient(BASE_URL, {
                extraHeaders: {
                  authorization: `Bearer ${UserService.getToken()}`,
                },
            });      
            setSocket(Socket);  
        }
       } catch (error) {
            //console.log(error, 'error_f')
       }

    };

    useEffect(() => {
        connectSocket()
    }, [])

    useEffect(() => {
        if(socket){
            socket.on('connect', () => {
                // alert("JAY__SOCKET_CONNECTED")
                //console.log('socket object is :', { socket});
            });
            socket.emit('entermessagesport')
            socket.on("new:message", (...args) => {
                //console.log("very new message user context", args[0])
                    
                let messages = args[0].messages
                let messageId = messages[0].sender_id === userInformation.id ? messages[0].recipient_id : messages[0].sender_id 
                if(messageId){
                    dispatch(addNewMessages({
                      messageId,
                        messages
                    }))
                }
            })
        }       
        
    },[socket])


    const emitEvent = (room, data) => {
       try {
        if (socket) {
          socket?.emit(room || 'events', {
            ...data,
          });
        }
       } catch (error) {
            //console.log(error)
       }
    };
    return (
        <UserContext.Provider value={{
            userInformation,
            emitEvent,
            socket,
            setSocket,
            connectSocket,
            activePage,
            setActivePage
        }}>
            {children}
        </UserContext.Provider>
    );
}
 
export default UserContextProvider;