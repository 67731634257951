import React, { useEffect, useState } from "react";
import { Text, Box, Image, Flex, Heading, HStack, Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import logo_2 from "../../public/icons/logo_.svg";
import home from "../../public/icons/home.svg";
import welcome from "../../public/icons/welcome.svg";
import IsDesktop from "../../utils/IsDesktop";
import rocket from "../../public/icons/rocket.svg";
import FounderSurveyForm from "./FounderSurveyForm";
import InvestorSurveyForm from "./InvestorSurveyForm";
import { useData } from "../../data";
import { useSelector } from "react-redux";
import { AiOutlineHome } from "react-icons/ai";
import { CustomToast } from "../../components/toast/CustomToast";
import { mutateFunction, useMutation } from "../../libs/apis";
import useCustomMutation from "../../libs/apis/useMutation";
import evaluateCompanyProfile from "../../utils/evaluateCompanyProfile";
import ExpertSurveyForm from "./ExpertSurveyForm";

const SurveyForm = () => {
  

  const completeSurvey = useSelector((state) => state?.survey?.completeSurvey);
  const { data } = useData("survey");
  const { data: profile, isLoading: isLoadingCompanyData } = useData("me");
  const { data: userCompany, refetch } = useData("user-company");
  const surveyData = data?.data;
  const navigate = useNavigate();
  const isDesktop = IsDesktop();
  const { addToast } = CustomToast();
  const { mutate, isLoading } = useCustomMutation(mutateFunction);

  const [pageNumber, setPageNumber] = useState(0);

  const [selectedProfile, setSelectedProfile] = useState("");
  const [page, setPage] = useState("");
  const [filledData, setFilledData] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    if (profile?.userProfile?.stage) {
      setPageNumber(profile?.userProfile?.stage);
      
      if (profile?.userProfile?.userTypeId === 1) {
        setPage("founder");
      } else if (profile?.userProfile?.userTypeId === 2) {
        setPage("investor");
      } else if (profile?.userProfile?.userTypeId === 3) {
        setPage("expert");
      }

    }else{
      setPage("default")
    }
  }, [profile?.userProfile?.stage, profile?.userProfile?.userTypeId]);

  const startSurvey = () => {
    setPageNumber(1);
    if (selectedProfile === "founder") {
      setFilledData((prev) => ({
        ...prev,
        userTypeId: 1,
      }));
    } else if (selectedProfile === "investor") {
      setFilledData((prev) => ({
        ...prev,
        userTypeId: 2,
      }));
    } else if (selectedProfile === "expert") {
      setFilledData((prev) => ({
        ...prev,
        userTypeId: 3,
      }));
    }
    setPage(selectedProfile);
  };

  const DefaultPage = () => {
    return (
      <>
        <Flex
          mb={7}
          color="brand.primary"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
        >
          <Box
            display="flex"
            justifyContent="center"
            className="max-w-[300px] md:max-w-max"
            w={isDesktop ? "600px" : "90vw"}
          >
            <Heading
              textAlign={"center"}
              fontSize={isDesktop ? "25px" : "20px"}
              mb={isDesktop ? 7 : 3}
            >
              Are you a founder, an investor or an expert?
            </Heading>
          </Box>
          <Text textAlign={"center"} className="p-5 md:p-2">
            Please select the user type that best matches your requirements.
          </Text>
        </Flex>
        <HStack
          flexDir={isDesktop ? "row" : "column"}
          mb={7}
          spacing={isDesktop ? "20px" : "0px"}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            cursor="pointer"
            mb={!isDesktop && 2}
            onClick={() => setSelectedProfile("founder")}
            px={2}
            py={2}
            h="180px"
            w="185px"
            borderRadius="10px"
            border="2px solid"
            color={selectedProfile === "founder" ? "white" : "gray"}
            bg={selectedProfile === "founder" ? "brand.primary" : "white"}
            borderColor={
              selectedProfile === "founder" ? "brand.primary" : "lightgray"
            }
          >
            {/* <Image src={home} size={30} mb={3} color="white" /> */}
            <AiOutlineHome size={20} />
            <Text fontWeight={700} fontSize="16px">
              Founder
            </Text>
            <Text fontSize="12px">
              {" "}
              Unlock your startup's potential by showcasing it to potential
              investors and gaining invaluable support from industry experts.
            </Text>
          </Box>
          <Box
            cursor="pointer"
            mb={!isDesktop && 2}
            onClick={() => setSelectedProfile("investor")}
            px={2}
            py={2}
            h="180px"
            w="185px"
            color={selectedProfile === "investor" ? "white" : "gray"}
            bg={selectedProfile === "investor" ? "brand.primary" : "white"}
            borderRadius="10px"
            border="2px solid"
            borderColor={
              selectedProfile === "investor" ? "brand.primary" : "lightgray"
            }
          >
            <AiOutlineHome size={20} />
            <Text fontWeight={700} fontSize="16px">
              Investor
            </Text>
            <Text fontSize="12px">
              Discover the gateway to unlocking the untapped potential of
              startups. Join our platform and be part of the thriving
              entrepreneurial ecosystem.
            </Text>
          </Box>
          <Box
            cursor="pointer"
            mb={!isDesktop && 2}
            onClick={() => setSelectedProfile("expert")}
            px={2}
            py={2}
            h="180px"
            w="185px"
            color={selectedProfile === "expert" ? "white" : "gray"}
            bg={selectedProfile === "expert" ? "brand.primary" : "white"}
            borderRadius="10px"
            border="2px solid"
            borderColor={
              selectedProfile === "expert" ? "brand.primary" : "lightgray"
            }
          >
            <AiOutlineHome size={20} />
            <Text fontWeight={700} fontSize="16px">
              Expert
            </Text>
            <Text fontSize="12px">
              Guide founders and help their startups unlock their potential. Help founders build a strong customer base through solving niche industry problems.
            </Text>
          </Box>
        </HStack>
        <Flex justifyContent="center" alignItems="center">
          <Box
            as="button"
            w="300px"
            h="50px"
            bg="brand.primary"
            borderRadius="5px"
            onClick={() => {
              let userTypeId = selectedProfile === "founder" ? 1 : selectedProfile === "expert" ? 3 : 2;
              mutate(
                { key: `companies/1`, method: "post", data: { userTypeId } },
                {
                  onSuccess(res) {
                    if (res.status) {
                      selectedProfile !== "" && setPage("welcome");

                      // addToast({
                      //   message: "Saved Company data Successfully",
                      //   type: "success",
                      // });
                      // const company = res?.data?.data?.company;
                      // console.log(company, "useruserss");

                      // UserService.setCompanyInfo(company);
                      // dispatch(setCompanyInfo(company));
                      // const newUserData = {
                      //   ...userInfo,
                      //   userTypeTitle: "founder",
                      //   userTypeId: 1,
                      // };
                      // dispatch(setUserInfo(newUserData));
                      // UserService.setUserInfo(newUserData);
                      // setButtonLoading(false);
                      // setPageNumber(pageNumber + 1);
                      // addToast(res);
                    }
                  },
                  onError(e) {
                    addToast(e.message);
                  },
                }
              );
            }}
          >
            <Text fontWeight={700} fontSize="16px" color="white">
              Get Started
            </Text>
          </Box>
        </Flex>
      </>
    );
  };
  const WelcomePage = () => {
    return (
      <>
        <Flex
          mb={7}
          color="brand.primary"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
        >
          <Heading maxW="700px" fontSize="24px" textAlign="center" mb={7}>
            Welcome, filling our survey will help us match you with the right{" "}
            {selectedProfile === "founder" && <span>investors.</span>}
            {selectedProfile === "investor" && <span>startups.</span>}
            {selectedProfile === "expert" && <span>founders.</span>}
          </Heading>
          <Text>
            Take this quick and easy survey to customize our services to your
            needs.
          </Text>
        </Flex>
        <HStack
          mb={7}
          spacing="20px"
          justifyContent="center"
          alignItems="center"
        >
          <Image src={welcome} size={30} mb={3} />
        </HStack>
        <Flex justifyContent="center" alignItems="center" flexDir="column">
          <Box
            as="button"
            w="300px"
            h="50px"
            mb={7}
            bg="brand.primary"
            borderRadius="5px"
            onClick={startSurvey}
          >
            <Text fontWeight={700} fontSize="16px" color="white">
              Complete Profile
            </Text>
          </Box>
          {/* <Link>I'll do this later</Link> */}
          {/* this box is temporary... use the link tag */}
          {/* <Box cursor="pointer" onClick={() => setPage("default")}>
            <Text textDecoration="underline">I'll do this later</Text>
          </Box> */}
        </Flex>
      </>
    );
  };
  const ThankYouPage = () => {
    return (
      <>
        <Flex
          mb={7}
          color="brand.primary"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
        >
          <Box
            display="flex"
            justifyContent="center"
            w={isDesktop ? "600px" : "300px"}
          >
            <Heading
              textAlign="center"
              fontSize={isDesktop ? "25px" : "20px"}
              mb={isDesktop ? 7 : 3}
            >
              Thank you for completing the survey!
            </Heading>
          </Box>
          <Box w={isDesktop ? "600px" : "300px"}>
            <Text textAlign="center" fontSize={14}>
              With this information, we will be able to match you with the right
              {selectedProfile === "founder" ? " investors." : " founders." }
            </Text>
          </Box>
        </Flex>
        <HStack
          mb={7}
          spacing="20px"
          justifyContent="center"
          alignItems="center"
        >
          <Image src={rocket} size={30} mb={3} />
        </HStack>
        <Flex justifyContent="center" alignItems="center" flexDir="column">
          <Box
            as="button"
            w="300px"
            h="50px"
            mb={7}
            bg="brand.primary"
            borderRadius="5px"
            onClick={() => {
              if(userCompany?.data?.user?.userTypeId == 1){
                setTimeout(() => {
                  evaluateCompanyProfile(userCompany?.data || {}, navigate)
                }, 1500)
              }
              navigate("/feed")
            }}
          >
            <Text fontWeight={700} fontSize="16px" color="white">
              Go To Your Feed
            </Text>
          </Box>
          {/* <Link>I'll do this later</Link> */}
          {/* this box is temporary... use the link tag */}
          {/* <Box cursor="pointer" onClick={() => navigate("/dashboard")}>
            <Text textDecoration="underline">I'll do this later</Text>
          </Box> */}
        </Flex>
      </>
    );
  };
  if (isLoadingCompanyData || !surveyData){
    return<>
      <Spinner/>
    </>
  }
  return (
    <>
      {completeSurvey === true ? (
        <>
          <Flex
            mt={20}
            mb={0}
            justifyContent="center"
            alignItems="center"
            flexDir="column"
          >
            <Box>
              <Image src={logo_2} boxSize='100px' className="mt-5" objectFit='cover' mb={10} />
            </Box>
          </Flex>
          <FounderSurveyForm pageNumber={7} setPage={setPage} />
        </>
      ) : (
        <>
          <Flex
            mt={20}
            mb={0}
            justifyContent="center"
            alignItems="center"
            flexDir="column"
          >
            <Box>
              <Image src={logo_2} boxSize='100px' className="mt-5" objectFit='cover' mb={10} />
            </Box>
          </Flex>
          {page === "default" && <DefaultPage />}
          {page === "welcome" && <WelcomePage />}
          
          <>
          {page === "thank-you" && <ThankYouPage />}
          {page === "founder" && (
            <FounderSurveyForm
              filledData={filledData}
              setFilledData={setFilledData}
              surveyData={surveyData}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setPage={setPage}
              userCompany={userCompany?.data}
              refetch={refetch}

            />
          )}
          {page === "investor" && (
            <InvestorSurveyForm
              filledData={filledData}
              setFilledData={setFilledData}
              surveyData={surveyData}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setPage={setPage}
              userCompany={userCompany?.data}
              refetch={refetch}
            />
          )}

          {page === "expert" && (
            <ExpertSurveyForm
              filledData={filledData}
              setFilledData={setFilledData}
              surveyData={surveyData}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setPage={setPage}
              userCompany={userCompany?.data}
              refetch={refetch}
            />
          )}
          </>
          
        </>
      )}
    </>
  );
};
export default SurveyForm;
