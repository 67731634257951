import {
  Box,
  Text,
  Flex,
  Avatar,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import IsDesktop from "../../utils/IsDesktop";
import useData from "../../data/useData";
import { useDispatch, useSelector } from "react-redux";
import BrandButton from "../brandButton/BrandButton";
import { useNavigate } from "react-router-dom";
import { mutateFunction, useMutation } from "../../libs/apis";
import { useQueryClient } from "react-query";
import { CustomToast } from "../toast/CustomToast";
import { setChatInfo } from "../../redux-toolkit/reducers/chatSlice";
import { BsChatLeftText } from "react-icons/bs";

const RequestListsTabs = ({ 
  onClose = () => {},
  notifications=[],
  onReadNotification=()=>{},
  onReactToRequest=()=>{},
}) => {
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const { data: connectionsData, isLoading, refetch : refetchConnections } = useData(
    `users/${loggedInUserId}/connections?page_no=1&page_size=20`, 30000
  );
  const [senderIds, setSenderIds] = useState([])
  const navigate = useNavigate();
  const isDesktop = IsDesktop();
  const [receivedConnections, setReceivedConnections] = useState([]);
  const [sentConnections, setSentConnections] = useState([]);
  const { mutate, isLoading: mutateIsLoading } = useMutation(mutateFunction);
  const queryClient = useQueryClient();
  const { addToast } = CustomToast();
  const dispatch = useDispatch();

  useEffect(() => {
    if (connectionsData?.data) {
      setReceivedConnections(
        connectionsData?.data?.connections.filter(
          (each) => each.target_user_id === loggedInUserId && each?.accepted !== true
        )
      );
      setSentConnections(
        connectionsData?.data?.connections.filter(
          (each) => each.target_user_id !== loggedInUserId
        )
      );
    }
  }, [connectionsData]);

  useEffect(() => {
    setSenderIds(notifications.map((item) => item.senderId))
  }, [notifications?.length])

  const markAsReadNotificationId = (id) => {
    if(notifications?.length > 0){
      const notification = notifications.filter(({senderId}) => senderId === id)
      if(notification?.length > 0){
        onReadNotification(notification[0].id)
      }
    }
  }

  const acceptRequest = (connectionRequest) => {
    markAsReadNotificationId(connectionRequest.id)
    mutate(
      {
        key: `users/${loggedInUserId}/connections/${connectionRequest?.connection?.connection_id}`,
        method: "put",
      },
      {
        onSuccess(res) {
          addToast(res);
          refetchConnections()
          onReactToRequest()
          queryClient.invalidateQueries(`users`);
        },
      }
    );
  };

  const declineRequest = (connectionRequest) => {
    markAsReadNotificationId(connectionRequest.id)
    mutate(
      {
        key: `users/${loggedInUserId}/connections/${connectionRequest?.connection?.connection_id}`,
        method: "delete",
      },
      {
        onSuccess(res) {
          addToast(res);
          refetchConnections()
          onReactToRequest()
          queryClient.invalidateQueries(`users`);
        },
      }
    );
  };
  const chatWithConnection = (connectionRequest) => {
    dispatch(setChatInfo(connectionRequest));
    navigate("../connections");
    onClose();
  };

  return (
    <>
      {receivedConnections?.length > 0 && senderIds?.length > 0 ? (
              receivedConnections.filter((eachConnection) => senderIds.includes(eachConnection.id)).map((eachConnection) => (
                <>
                  <Box
                    key={eachConnection.id}
                    mb={2}
                    w="100%"
                    className="flex p-3 flex-wrap justify-between border-b border-[#DBE2ED] gap-3 "
                  >
                    <Box className="flex items-center gap-2">
                        <Avatar
                          borderRadius="1000"
                          name={`${eachConnection.first_name} ${eachConnection.last_name}`}
                          src={eachConnection.profile_image}
                          size={"sm"}
                        />
                        <Text
                        className="text-base overflow-hidden text-ellipsis whitespace-nowrap w-[20ch] font-medium"
                        >
                          {`${eachConnection.first_name} ${eachConnection.last_name}`}
                        </Text>
                    </Box>
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      className="gap-2 w-full"
                    >
                      {
                        !eachConnection.accepted &&(
                          <BrandButton
                            w={"50%"}
                            isLoading={mutateIsLoading}
                            fontSize="13px" 
                            py={2}  
                            text="Decline"
                            color="#F2112D" 
                            borderColor="#F2112D"
                            bg="#FFF"
                            _hover={{ backgroundColor: "red.50" }}
                            height="36px"
                            onClick={() => declineRequest(eachConnection)}
                          />
                        )
                      }
                      {!eachConnection.accepted && (
                        <BrandButton
                          fontSize="13px" 
                          bg="#20B962" 
                          py={2}
                          w={"50%"}
                          isLoading={mutateIsLoading}
                          text="Accept"
                          height="36px"
                          colorScheme="blue"
                          onClick={() => acceptRequest(eachConnection)}
                        />
                      )}
                      {eachConnection.accepted && (
                        <>
                          <BrandButton
                            isLoading={mutateIsLoading}
                            h="35px"
                            text="Chat"
                            colorScheme="blue"
                            display={{ base: "none", md: "flex" }}
                            onClick={() => chatWithConnection(eachConnection)}
                          />
                          <Box
                            onClick={() => chatWithConnection(eachConnection)}
                            display={{ base: "flex", md: "none" }}
                            justifyContent={"flex-end"}
                            cursor={"pointer"}
                            width={"100%"}
                          >
                            <BsChatLeftText style={{ color: "#134A70" }} />
                          </Box>
                        </>
                      )}
                    </Flex>
                  </Box>
                </>
              ))
            ) : (
              <>
                {isLoading ? (
                  <Box className="w-full flex items-center justify-center my-4"><Spinner /></Box>
                ) : (
                  <Box className="w-full flex items-center justify-center my-4">
                      <Text className="text-sm text-gray-700 text-center">
                        You have no new requests.
                      </Text>
                  </Box>
                )}
              </>
      )}
    </>
  );
};
export default RequestListsTabs;
