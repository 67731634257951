import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { CustomToast } from "../toast/CustomToast";
import {
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
  Button,
  Textarea,
  Box,
} from "@chakra-ui/react";
import BrandButton from "../brandButton/BrandButton";
import { Avatar } from "antd";
import { mutateFunction } from "../../libs/apis";
import moment from "moment";
import { useSelector } from "react-redux";
import { UserContext } from "../../context/UserContext";
import AutoResizeTextarea from "./AutoResizeTextArea";
import { comment } from "postcss";

const CreateReply = ({
    comment,
    postId,
    onClose = () => {},
    onSuccess = () => {},
    showCloseButton = true,
    showPost = true,
    refetchPost = () => {}
}) => {
    const { mutate, isLoading } = useMutation(mutateFunction);
    const { addToast } = CustomToast();
    const [postMessage, setPostMessage] = useState("");
    const {userInformation} = useContext(UserContext);
    const formSubmit = async (e) => {
        e.preventDefault()
        if(!postMessage.trim()) {
            addToast({message: 'Please add a comment'}, "warning")
            return 
        }

        mutate(
        { key: "comment/create", method: "post", data : {
            text: `${postMessage.trim()}`,
            post_id: postId,
            reply_to: comment.id
        }},
        {
            onSuccess(res) {
                addToast(res);
                onClose(true)
                setPostMessage('')
                refetchPost()
                onSuccess({
                  ...res.data.comment,
                  user: {
                    first_name: userInformation.firstName,
                    last_name: userInformation.lastName,
                    profile_image: userInformation.profile_image
                  }
                })
            },
            onError(e){
                addToast({message: 'Commennt was not created'}, "error")
            }
        }
        );
    };

    console.log("The comment==", comment)

    return (
        <Flex flexDirection="column">
              <Stack spacing={6}>
                {
                    showPost ? (
                        <Flex className="items-start w-full mb-5 gap-2" justify={"center"}>
                            <Box>
                                <Avatar 
                                    src={comment.user.profile_image ?? null}
                                    name={`${comment.user.first_name} ${comment.user.last_name}`}
                                    size={"md"}
                                />
                            </Box>
                            <Box className="flex flex-col w-full">
                                <Box className="flex items-center text-sm gap-2">
                                    <Text className="font-bold">{`${comment.user.first_name} ${comment.user.last_name}`}</Text>
                                    <Text className="text-xs text-gray-600">{moment(comment.createdAt).fromNow()}</Text>
                                </Box>
                                <Text className="preview_message text-sm w-full" color="#000" textOverflow="ellipsis">
                                    {comment?.text.split('\n\n').map(text => <span>{text}<br/></span>)}
                                </Text>
                            </Box>
                        </Flex>
                    ) : null
                }
              </Stack>
              <Box mt={"20px"} className="flex items-start gap-2">
                <Box>
                        <Avatar 
                            src={userInformation.profile_image}
                            name={`${userInformation.firstName} ${userInformation.lastName}`}
                            size={"md"}
                        />
                    </Box>
              <Flex
                borderBottomWidth="1px"
                borderColor="brand.primary"
                alignItems="center"
                className="w-full"
              >
                <form className="w-full" onSubmit={formSubmit} >
                  <AutoResizeTextarea
                    focusBorderColor="#"
                    borderWidth="0px"
                    variant="outline"
                    isRequired={true}
                    name="postMessage"
                    value={postMessage}
                    onChange={(e) => setPostMessage(e.target.value)}
                    placeholder="Say something...."
                    type="postMessage"
                    w={"full"}
                    maxLength={150}
                  />
                </form>
              </Flex>
              </Box>
              <Box className="flex justify-end my-1">
                <Text className={`text-xs font-semibold ${postMessage.length < 150 ? 'text-gray-600' : 'text-red-800'}`}>
                  {postMessage.length} / {150} characters
                </Text>
              </Box>
              <Flex gap={10} className={`mt-6 ml-10 items-center ${showCloseButton ? 'justify-between' : 'justify-end'}`}>
                {
                    showCloseButton ? (
                        <BrandButton
                            text="Close"
                            onClick={onClose}
                            />
                    ) : null
                }
                <BrandButton
                  isLoading={isLoading}
                  text={`Reply`}
                  onClick={formSubmit}
                  type="submit"
                  variant="md"
                  bgColor="brand.primary"
                  color="#fff"
                  fontSize="14px"
                  borderRadius={4}
                  px={4}
                  _hover={{ background: "brand.primary" }}
                />
              </Flex>
            </Flex>
    )
}


export default CreateReply