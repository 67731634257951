import { Box, Text } from "@chakra-ui/react"

const Trending = () => {
    return (
        <Box className="flex flex-col p-3 w-full bg-white rounded-xl">
                <Text className="text-lg font-bold">
                    What's Happening
                </Text>
                <Box className="py-14">
                    <Text className="text-sm text-gray-700 text-center">
                        No trends to show
                    </Text>
                </Box>
        </Box>
    )
}

export default Trending