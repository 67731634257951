import { useContext, useRef, useState } from "react";
import { useMutation } from "react-query";
import { CustomToast } from "../toast/CustomToast";
import {
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
  Button,
  Textarea,
  Box,
} from "@chakra-ui/react";
import BrandButton from "../brandButton/BrandButton";
import { Avatar } from "antd";
import { mutateFunction } from "../../libs/apis";
import { useSelector } from "react-redux";
import { UserContext } from "../../context/UserContext";
import AutoResizeTextarea from "./AutoResizeTextArea";
import { TbPhoto } from "react-icons/tb";
import { CloseIcon } from "@chakra-ui/icons";

const CreatePost = ({
    onClose = () => {},
    showCloseButton = true,
    refetchPost = () => {},
    appendToPostList = (data) => {},
}) => {
    const { mutate, isLoading } = useMutation(mutateFunction);
    const { addToast } = CustomToast();
    const [postMessage, setPostMessage] = useState("");
    const {userInformation} = useContext(UserContext);
    const [images, setImages] = useState([])
    const imagesInputRef = useRef(null)
    const formSubmit = async (e) => {
        e.preventDefault()

        if(!postMessage.trim()) {
            addToast({message: 'Please add some text'}, "warning")
            return 
        }

        const attachments = [...images]
        let formData = new FormData()
          formData.append("text", postMessage.trim())
          for (let i = 0; i < attachments.length; i++) {
            formData.append("attachments", attachments[i]);
          }

        mutate(
          { 
            key: "post", 
            method: "post", 
            data: formData,
          },
          {
            onSuccess(res) {
              addToast(res);
              onClose()
              setPostMessage('')
              setImages([])
              appendToPostList({
                ...res.data.post,
                user: {
                  first_name: userInformation.firstName,
                  last_name: userInformation.lastName,
                  profile_image: userInformation.profile_image
                }
              })
            },
            onError(e){
              addToast({message: 'Post was not created'}, "error")
            }
          }
        );
    };
    const maxBytes = 3485760

    const openImageUpload = (e) => {
      imagesInputRef.current.click()
    }

    const handleChangeForImage = e => {
      let isTooLarge = [...e.target.files].some(file => file.size > maxBytes)
      if(isTooLarge) {
        setTimeout(() => {
          addToast({ message: "One or more files are greater than 3MB", type: "warning"})
        }, 500)
        return
      }
      if(e.target.files.length < 8){
        setImages(e.target.files)
      } else {
        setTimeout(() => {
          addToast({ message: "More than 7 files selected", type: "warning"})
        }, 500)
      }
    }

    const clearData = () => {
      setImages([])
    }
  
    return (
        <Flex flexDirection="column">
              <Box mt={"20px"} className="flex items-start gap-2">
                  <Box>
                        <Avatar 
                            src={userInformation.profile_image}
                            name={`${userInformation.firstName} ${userInformation.lastName}`}
                            size={"md"}
                        />
                    </Box>
              <Flex
                borderBottomWidth="1px"
                borderColor="brand.primary"
                alignItems="center"
                className="w-full"
              >
                <form className="w-full" onSubmit={formSubmit} >
                  <AutoResizeTextarea 
                    focusBorderColor="#"
                    borderWidth="0px"
                    variant="outline"
                    isRequired={true}
                    name="postMessage"
                    value={postMessage}
                    onChange={(e) => setPostMessage(e.target.value)}
                    placeholder="What are you thinking...."
                    type="postMessage"
                    w={"full"}
                    maxLength={150}
                  />
                </form>
              </Flex>
              </Box>
              <Box className="flex justify-end my-1">
                <Text className={`text-xs font-semibold ${postMessage.length < 150 ? 'text-gray-600' : 'text-red-800'}`}>
                  {postMessage.length} / {150} characters
                </Text>
              </Box>
              <Box 
                className={`w-full min-h-[50px]`}
              >
                  {
                    images && images.length > 0 ? (
                      <Flex className="items-center w-full justify-between my-1 bg-gray-50 py-1 px-2 ">
                          <Flex className="w-full gap-3 items-center">
                            <Text className={`max-w-[60%] text-sm whitespace-nowrap overflow-hidden text-ellipsis`}>{[...images][0]?.name}</Text>
                            {
                              images.length > 1 ? (
                                <Box className="rounded-lg bg-[#134A70] text-white font-bold text-[11px] grid place-items-center w-6">
                                  {images.length < 11 ? `+${images.length - 1}` : '10+'}
                                </Box>
                              ) : null
                            }
                          </Flex>
                          <Box onClick={clearData} className="border cursor-pointer border-red-400 rounded-full px-1 grid place-items-center">
                            <CloseIcon color='red.400' w={2} />
                          </Box>
                      </Flex>
                    ) : null
                  }
              </Box>
              <Box className="flex border-t p-3 mt-6 justify-between pb-0 w-full">
                <Box onClick={openImageUpload} className="flex items-center gap-2 hover:font-medium cursor-pointer">
                          <Input
                              accept="image/*"
                              id="image-attachments"
                              type="file"
                              onChange={(e) => handleChangeForImage(e)}
                              name="attachments"
                              key="image-attachments"
                              hidden
                              multiple
                              ref={imagesInputRef}
                            />
                          <TbPhoto />
                          <Text className="text-sm">
                            Image
                          </Text>
                  </Box>
                <Flex gap={10} className={`ml-10 items-center ${showCloseButton ? 'justify-between' : 'justify-end'}`}>
                {
                      showCloseButton ? (
                          <BrandButton
                              text="Close"
                              onClick={onClose}
                              />
                      ) : null
                  }
                  <BrandButton
                    isLoading={isLoading}
                    text={`Post`}
                    onClick={formSubmit}
                    type="submit"
                    variant="md"
                    bgColor="brand.primary"
                    color="#fff"
                    fontSize="14px"
                    borderRadius={4}
                    px={4}
                    _hover={{ background: "brand.primary" }}
                  />
                </Flex>
              </Box>
            </Flex>
    )
}


export default CreatePost