import { Box } from "@chakra-ui/react"

const MediaDisplay = ({
    media = []
}) => {
    return (
        <>
            {
                media.length === 1  ? (
                    <Box className="mb-2">
                        {
                            media.map(img => (
                                <img 
                                    className="w-full h-full  object-cover"
                                    src={img}
                                />
                            ))
                        }
                    </Box>
                ) : media.length === 2  ? (
                    <Box className="grid grid-cols-2 gap-2 mb-2">
                        {
                            media.map(img => (
                                <img 
                                    className="w-full h-full object-cover"
                                    src={img}
                                />
                            ))
                        }
                    </Box>
                ) : media.length === 3 ? (
                    <Box className="grid grid-cols-2 gap-2 mb-2">
                        <img 
                            className="w-full h-full object-cover"
                            src={media[0]}
                        />
                        <Box className="flex flex-col gap-2">
                            {
                                [media[1], media[2]].map(img => (
                                    <img 
                                        className="w-full object-cover"
                                        src={img}
                                    />
                                ))
                            }
                        </Box>
                    </Box>
                ) : (
                    <Box className="grid grid-cols-2 gap-2 mb-2">
                        <Box className="flex flex-col gap-2">
                            {
                                [media[0], media[1]].map(img => (
                                    <img 
                                        className="w-full object-cover"
                                        src={img}
                                    />
                                ))
                            }
                        </Box>
                        <Box className="flex flex-col gap-2">
                            {
                                [media[2], media[3]].map(img => (
                                    <img 
                                        className="w-full object-cover"
                                        src={img}
                                    />
                                ))
                            }
                        </Box>
                    </Box>
                )
            }
        </>
    )
}

export default MediaDisplay