import React, { useState } from "react";
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";
import CreateReply from "../feed/CreateReply";

export default function CreateReplyModal({ 
  isOpen, 
  onClose, 
  postId,
  comment,
  refetchPost = () => {},
  onSuccess = () => {}
}) {

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            <Flex flexDirection="column">
              <CreateReply 
                onClose={onClose} 
                comment={comment}
                postId={postId}
                refetchPost={refetchPost}
                onSuccess={onSuccess}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
